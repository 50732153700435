import { useState, useEffect } from 'react';
import Translator from '../../../classes/Translator/Translator';
import OrdersService from './order.service';

const OrdersLogic = (userId, activeFilter) => {
  const { translator } = Translator();
  const [current, setCurrent] = useState(-1);
  const [children, setChildren] = useState([]);
  const [offers, setOffers] = useState([]);

  const fetchOffers = async () => {
    const service = OrdersService();
    const data = await service.getData(userId, activeFilter);
    if (Array.isArray(data.resources)) {
      setOffers(data.resources);
    } else {
      console.error('Error fetching offers:', data);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [userId, activeFilter]);

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };

  const handleRefreshOffers = () => {
    fetchOffers();
  };

  return {
    current,
    changeState,
    translator,
    children,
    offers,
    handleRefreshOffers,
  };
};

export default OrdersLogic;
