import Joi from 'joi';

const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,}$/;

const passwordSchema = Joi.string()
  .pattern(new RegExp(pattern, 'm'))
  .required();

export default function validator(values) {
  const errors = {};
  console.log('Values in validdator:', values);
  const {
    currentPassword,
    newPassword,
    confirmNewPassword,
    currentPasswordFromStorage,
  } = values;
  // Validate passwords individually
  const { error: errorCurrentPassword } =
    passwordSchema.validate(currentPassword);
  const { error: errorNewPassword } = passwordSchema.validate(newPassword);
  const { error: errorConfirmNewPassword } =
    passwordSchema.validate(confirmNewPassword);

  if (errorCurrentPassword) {
    errors.error_password = 'Ingresa un password válido.';
  }

  if (errorNewPassword) {
    errors.error_new_password = 'Ingresa un password válido.';
  }

  if (errorConfirmNewPassword) {
    errors.error_confirm_new_password = 'Ingresa un password válido.';
  }

  if (newPassword !== confirmNewPassword) {
    errors.error_no_match_new_password = 'Las contraseñas no coinciden.';
  }

  // Check if current password matches stored password
  /* if (currentPasswordFromStorage !== currentPassword) {
      errors.error_no_match_current_password = 'La contraseña actual no coincide.';
    } */

  console.log('Errors:', errors);
  return errors;
}
