import Translator from '../../classes/Translator/Translator';

const TransactionsLogic = () => {
    const { translator } = Translator();

    return {translator };
}

export default TransactionsLogic;



