import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Container,
  Grid,
  Typography,
  FormControl,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  useStyles,
  ProfileLogic,
  ProfileService,
  validator,
} from './profile.module';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import ButtonDefault from '../shared/button/button';
import AlertMessage from '../shared/alert/alert';
import CustomError from '../../classes/CustomError/CustomError';
import Storage from '../../classes/Storage/Storage';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { Select as AntSelect } from 'antd';
import DateRangeIcon from '@material-ui/icons/DateRange';
import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import User from '../../classes/User/User';
import Flags from '../../classes/Flags/Flags';
import FlagsData from '../../classes/Flags/FlagsData.json';
import 'antd/dist/antd.css';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';

/* to do
1. add date functionality
2. move all logic code to logic file, single handle change function
3. do it using vim shortcuts
4.  */

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const { Option } = AntSelect;

const Profile = () => {
  const classes = useStyles();
  const {
    current,
    changeState,
    handleSubmit,
    handleChange,
    handleClickShowCurrentPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmNewPassword,
    handleMouseDownPassword,
    values,
    errors,
    setErrors,
    setValues,
    buttonDisabled,
    translator,
    handleChangeDropdown,
  } = ProfileLogic(() => changePassword(), validator);

  const changePassword = async () => {
    const userId = new Storage('id').getItem();
    const { currentPassword, newPassword } = values;
    const passwords = { currentPassword, newPassword };

    const response = await ProfileService().changePassword(userId, passwords);

    if (response instanceof CustomError) {
      setErrors({ ...errors, api_error: response.message });
    } else {
      setValues({ ...values, fullRegistration: true });
    }
  };

  const [address, setAddress] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const handleChange4 = (event) => {
    const { name, value } = event.target;
    if (name === 'address') setAddress(value);
    if (name === 'unit_number') setUnitNumber(value);
    if (name === 'city') setCity(value);
    if (name === 'postal_code') setPostalCode(value);
    if (name === 'country') setCountry(value);
  };

  const getUser = async (id) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, User);
      return response.wasSuccessfull().single();
    }
  };
  const [user, setUser] = useState({ name: '', email: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    const id = new Storage('id').getItem();
    getUser(id)
      .then((user_values) => {
        setUser(user_values);
        console.log('user', user_values);
      })
      .catch((error) => {
        setError(error);
        console.log('error', error);
      });
  }, []);

  const { name, lastname } = user || {};

  const [selectedValue, setSelectedValue] = useState('');
  const [selectOption1, setSelectOption1] = useState('');
  const [selectOption2, setSelectOption2] = useState('');

  const handleChange3 = (event) => {
    const { name, value } = event.target;

    if (name === 'selectOption1') {
      setSelectOption1(value);
    } else if (name === 'selectOption2') {
      setSelectOption2(value);
    }
  };

  const handleChange2 = (event) => {
    setSelectedValue(event.target.value);
  };

  const [age, setAge] = React.useState('');
  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Filtered data based on search input
  const filteredData = FlagsData.filter((flag) =>
    flag.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleChange5 = (event) => {
    console.log('Selected country:', event.target.value);
    setSelectedCountry(event.target.value);
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className={classes.dashboardStyle}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                My Profile
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Accordion onChange={changeState(0)}>
                  <AccordionSummary
                    className={classes.root}
                    expandIcon={
                      current === 0 ? (
                        <RemoveCircleOutlineIcon
                          className={classes.removeCircleOutlineIcon}
                        />
                      ) : (
                        <AddCircleOutlineIcon
                          className={classes.addCircleOutlineIcon}
                        />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      color="initial"
                      align="left"
                      paragraph
                      className={classes.questionsWhite}
                    >
                      Reset Password
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid
                      className={classes.registerSection}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <form
                        className={classes.form}
                        noValidate
                        autoComplete={'off'}
                        //onSubmit={handleSubmit}
                      >
                        <label className={classes.labels}>
                          Current Password
                        </label>
                        <FormControl className={classes.passwordField}>
                          <OutlinedInput
                            type={
                              values.showCurrentPassword ? 'text' : 'password'
                            }
                            value={values.currentPassword}
                            name={'currentPassword'}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowCurrentPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  className={classes.icon}
                                >
                                  {values.showCurrentPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>

                        {errors &&
                          errors.error_password &&
                          (console.log('errors in the alert message 1', errors),
                          (
                            <AlertMessage
                              type="error"
                              text={errors.error_password}
                            />
                          ))}

                        <label className={classes.labels}>New Password</label>
                        <FormControl className={classes.passwordField}>
                          <OutlinedInput
                            type={values.showNewPassword ? 'text' : 'password'}
                            value={values.newPassword}
                            name={'newPassword'}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  className={classes.icon}
                                >
                                  {values.showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                        <p className={classes.passwordHelper}>
                          The password must contain a minimum of 8 characters,
                          at least one number, a capital letter and a special
                          character.
                        </p>
                        {errors &&
                          errors.error_new_password &&
                          (console.log('errors in the alert message', errors),
                          (
                            <AlertMessage
                              type="error"
                              text={errors.error_new_password}
                            />
                          ))}

                        <label className={classes.labels}>
                          Confirm New Password
                        </label>
                        <FormControl className={classes.passwordField}>
                          <OutlinedInput
                            type={
                              values.showConfirmNewPassword
                                ? 'text'
                                : 'password'
                            }
                            value={values.confirmNewPassword}
                            name={'confirmNewPassword'}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  className={classes.icon}
                                >
                                  {values.showConfirmNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                        <p className={classes.passwordHelper}>
                          The password must contain a minimum of 8 characters,
                          at least one number, a capital letter and a special
                          character.
                        </p>
                        {errors &&
                          errors.error_confirm_new_password &&
                          (console.log('errors in the alert message 1', errors),
                          (
                            <AlertMessage
                              type="error"
                              text={errors.error_confirm_new_password}
                            />
                          ))}

                        {errors &&
                          errors.error_no_match_new_password &&
                          (console.log('errors in the alert message 1', errors),
                          (
                            <AlertMessage
                              type="error"
                              text={errors.error_no_match_new_password}
                            />
                          ))}
                      </form>
                      <ButtonDefault
                        type="blue"
                        text="reset"
                        changeView={handleSubmit}
                        disabled={buttonDisabled}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion onChange={changeState(1)}>
                  <AccordionSummary
                    className={classes.root}
                    expandIcon={
                      current === 0 ? (
                        <RemoveCircleOutlineIcon
                          className={classes.removeCircleOutlineIcon}
                        />
                      ) : (
                        <AddCircleOutlineIcon
                          className={classes.addCircleOutlineIcon}
                        />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5" className={classes.questionsWhite}>
                      Verify Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <FormControl className={classes.inputField}>
                          <label className={classes.labels}>First Name</label>
                          <OutlinedInput
                            type="text"
                            value={name}
                            name="firstName"
                            onChange={handleChange}
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <FormControl className={classes.inputField}>
                          <label className={classes.labels}>Last Name</label>
                          <OutlinedInput
                            value={lastname}
                            name={'confirmNewPassword'}
                            onChange={handleChange}
                            labelWidth={30}
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >

<Grid container direction="column" alignItems="flex-start">
<label className={classes.labels}>Date of Birth</label>
      <Grid item className={classes.datePickerContainer}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              className: classes.icon,
            }}
            DialogProps={{
              className: classes.calendarPopup,
            }}
            className={classes.datePickerInput}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>

                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        style={{
                          border: '1px solid #E0E0E0', //
                          borderRadius: '8px', //
                          paddingLeft: '5px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      >
                        <label className={classes.labels}>Address</label>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <FormControl className={classes.inputField} fullWidth>
                            <OutlinedInput
                              label="Address"
                              value={address}
                              onChange={handleChange4}
                              name="address"
                              placeholder="Address"
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <FormControl className={classes.inputField} fullWidth>
                            <OutlinedInput
                              label="Unit Number"
                              value={unitNumber}
                              onChange={handleChange4}
                              name="unit_number"
                              placeholder="Unit Number"
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <FormControl className={classes.inputField} fullWidth>
                            <OutlinedInput
                              label="City"
                              value={city}
                              onChange={handleChange4}
                              name="city"
                              placeholder="City / Town / District"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item lg={7} md={7} sm={7} xs={12}>
                          <FormControl className={classes.inputField} fullWidth>
                            <OutlinedInput
                              label="Postal Code"
                              value={postalCode}
                              onChange={handleChange4}
                              name="postal_code"
                              placeholder="Postal Code"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item lg={5} md={5} sm={5} xs={12}>
                          <AntSelect
                            showSearch
                            placeholder="Select Country"
                            onChange={onChange}
                            style={{ width: '100%', margin: '0' }}
                            className={classes.outlinedSelect}
                            filterOption={(input, option) => {
                              const { children } = option.props;

                              const value = Array.isArray(children)
                                ? children[children.length - 1]
                                : children;
                              return (
                                value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {Flags.flagData()}
                          </AntSelect>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginTop: '10px',
                          flexDirection: 'column',
                        }}
                      >
                        <label className={classes.labels}>
                          WHY ARE YOU INTERESTED IN EGREEN?
                        </label>
                        <Select
                          value={selectedValue}
                          onChange={handleChange2}
                          input={<BootstrapInput />}
                          align="left"
                          fullWidth
                          color="theme.palette.text.tertiary"
                        >
                          <MenuItem value="">
                            <em>Select an option</em>{' '}
                          </MenuItem>
                          <MenuItem value={10}>Invest For Profit</MenuItem>
                          <MenuItem value={20}>Environmental Impact</MenuItem>
                          <MenuItem value={30}>Help green projects</MenuItem>
                        </Select>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginTop: '10px',
                          flexDirection: 'column',
                        }}
                      >
                        <label className={classes.labels}>SOURCE INCOME</label>
                        <Select
                          value={selectOption1}
                          onChange={handleChange3}
                          name="selectOption1"
                          input={<BootstrapInput />}
                          align="left"
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Select an option</em>{' '}
                          </MenuItem>
                          <MenuItem value={10}>Job Income</MenuItem>
                          <MenuItem value={20}>Real Estate</MenuItem>
                          <MenuItem value={30}>Investment Family</MenuItem>
                        </Select>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginTop: '2px',
                          flexDirection: 'column',
                        }}
                      >
                        <label className={classes.labels}>
                          EMPLOYMENT STATUS
                        </label>
                        <Select
                          value={selectOption2}
                          onChange={handleChange3}
                          name="selectOption2"
                          align="left"
                          input={<BootstrapInput />}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Select an option</em>{' '}
                          </MenuItem>
                          <MenuItem value={10}>Independent Contractor</MenuItem>
                          <MenuItem value={20}>Payroll</MenuItem>
                          <MenuItem value={30}>Student</MenuItem>
                          <MenuItem value={40}>Unemployed</MenuItem>
                          <MenuItem value={50}>Other</MenuItem>
                        </Select>
                      </Grid>

                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ButtonDefault
                          type="blue"
                          text="Continue"
                          changeView={handleSubmit}
                          disabled={buttonDisabled}
                          align="center"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Profile;
