import Formatter from '../Formatter/Formatter';
import CustomDate from '../CustomDate/CustomDate';

class Transaction {
  static get BUY_BONDS() {
    return 'buy_bonds';
  }
  static get BUY_USD() {
    return 'buy_usd';
  }

  static get BUY_NFT() {
    return 'buy_nft';
  }
  static get BUY_SBT() {
    return 'buy_sbt';
  }

  static get DICTIONARY_TYPES() {
    return {
      [Transaction.BUY_BONDS]: 'EGRN',
      [Transaction.BUY_NFT]: 'NFT',
      [Transaction.BUY_SBT]: 'SBT',
      [Transaction.BUY_USD]: 'USD',
    };
  }

  static get DICTIONARY_CONFIRMATION() {
    return {
      [Transaction.BUY_BONDS]: {
        title: 'You are buying an EGRN',
        message: 'Are you sure you want to continue buying an EGRN?',
      },
      [Transaction.BUY_NFT]: {
        title: 'You are buying an NFT',
        message: 'Are you sure you want to continue buying an NFT?',
      },
      [Transaction.BUY_SBT]: {
        title: 'You are buying an SBT',
        message: 'Are you sure you want to continue buying an SBT?',
      },
    };
  }

  constructor({
    type,
    id_user,
    project_invested,
    project_id,
    carbon_credit_invested,
    client_coins,
    total_coins,
    co2_emission,
    fee,
    payment_amount,
    date,
    _id,
    id,
    hash,
  }) {
    this.type = type;
    this.id_user = id_user;
    this.project_invested = project_invested;
    this.project_id = project_id;
    this.carbon_credit_invested = carbon_credit_invested;
    this.client_coins = client_coins;
    this.total_coins = total_coins;
    this.co2_emission = co2_emission;
    this.fee = Number(fee);
    this.payment_amount = payment_amount;
    this.date = date;
    this.id = _id || id;
    this.hash = hash;
  }

  getTypeStr() {}

  get subtotal() {
    return Formatter.round(this.total - this.feeValue).toFixed(2);
  }

  get total() {
    return Formatter.round(this.payment_amount / 100);
  }

  get feeValue() {
    return this.type === Transaction.BUY_USD
      ? `${Formatter.round(this.fee)}`
      : `${Formatter.round(this.fee / 100)}`;
  }

  get formattedDate() {
    return new CustomDate(this.date).formatted_date;
  }

  get totalCoins() {
    return this.total_coins <= 1
      ? `${this.client_coins} EGRN COIN`
      : `${this.client_coins} EGRN COINS`;
  }

  get formattedTypeOfTransaction() {
    const data = {
      [Transaction.BUY_BONDS]: `${this.totalCoins}`,
      [Transaction.BUY_NFT]: `${this.total_coins} NFT`,
      [Transaction.BUY_SBT]: 'SBT',
      [Transaction.BUY_USD]: 'TRANSFER TO BUY',
    };
    return data[this.type];
  }

  get formattedUSDValue() {
    return `${this.total}`;
  }

  get fotCreateBonds() {
    const {
      type,
      id_user,
      project_invested,
      project_id,
      carbon_credit_invested,
      client_coins,
      total_coins,
      co2_emission,
    } = this;
    return {
      type,
      id_user,
      project_invested,
      project_id,
      carbon_credit_invested,
      client_coins,
      total_coins,
      co2_emission,
    };
  }

  get fotCreateUsd() {
    // Todo: Implementar cuando se puedan comprar USD desde new page
    const { type } = this;
    return { type };
  }
}

export default Transaction;
