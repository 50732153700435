import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  useStyles,
  MinorProjectCardLogic,
  PropTypes,
} from './minorProjectCard.module';
import ButtonDefault from '../button/button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const MinorProjectCard = (props) => {
  const classes = useStyles();
  const { values, goToProject, handleInvest, goToProjects } =
    MinorProjectCardLogic(props);
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardActionArea>
          <CardMedia
            onClick={goToProject}
            className={classes.media}
            image={values.project.image}
            title="Project Main Image"
          />
          <Grid
            container
            spacing={1}
            className={classes.cardContentMinorProjectCard}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.cardMinorProjectTitle}
            >
              <Typography className={classes.minorProjectTitle} gutterBottom>
                {values.project.title_project}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.cardMinorProjectText}>
                {values.project.description}
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </CardActionArea>
      <CardActions className={classes.cardActionsMinorProject}>
        <ButtonDefault
          id={values.project.id}
          type="greenMint"
          text={props.text}
          changeView={goToProject}
        >
          <ArrowRightAltIcon className={classes.iconButtonMinorProject} />
        </ButtonDefault>
      </CardActions>
    </Card>
  );
};
MinorProjectCard.propTypes = {
  image: PropTypes.string,
  title_project: PropTypes.string,
  description: PropTypes.string,
};

export default MinorProjectCard;
