import React from 'react';
import {
    Paper,
    Container,
    Box,
    Typography,
    Button
} from '@material-ui/core'
import {useStyles, CardOpenOffersLogic} from './carOpenOffers.module'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CardOpenOffer({offerData, onClick,openFlag = false, deleteFlag = true, onRefresh}) {
  const classes = useStyles();
  const { translator, handleDelete, loading } = CardOpenOffersLogic(onRefresh);
  
  return (
    <Paper className={classes.paper}>
      <Container className={classes.container}>
        <Typography className={classes.textDate}>{offerData.formattedDate}</Typography>
        <Typography className={classes.textBold}>{offerData.type === "BUY" ? translator('orders.buy') : translator('orders.sell')}</Typography>
        <Box className={classes.priceBox}>
            <Typography className={classes.textDate}>EGRN</Typography>
            <Typography className={classes.textNumber}>{offerData.price}</Typography>
            <Typography className={classes.textSemi}>USD {offerData.total}</Typography>
        </Box>  
        <Box className={classes.boxButtons}>

          { openFlag ? <Button onClick={() => onClick(offerData)} className={classes.customButton}>OPEN</Button>: ''}  
            { deleteFlag ? <Button className={classes.customButton} onClick={() => handleDelete(offerData._id)}>                        
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                translator('card_orders.delete')
              )}</Button>
            : ''}                         
        </Box>
      </Container>      
    </Paper>
  );
}
