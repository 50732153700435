import React from 'react';
import { Card, Typography, CardContent, useTheme } from '@material-ui/core';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TransactionsLogic, useStyles } from './transactions.module';

const Transactions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { translator } = TransactionsLogic();

  return (
    <Card
      className={
        theme.palette.mode === 'light'
          ? classes.rootTransactions
          : classes.rootLightTransactions
      }
    >
      <CardContent className={classes.cardContainerTransactions}>
        <Accordion classes={{ root: classes.MuiAccordionrootTransactions }}>
          <AccordionSummary
            expandIcon={
              <KeyboardArrowDownIcon
                className={classes.keyboardArrowDownIconTransactions}
              />
            }
          >
            <Typography variant="h5" color="initial" align="left" paragraph>
              {translator('transactions.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionDetailsTransactions}
          ></AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default Transactions;
