import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
      paddingLeft:30,
      paddingRight:30
    },
    height: '100%',
  },
  rootLight: {
    border: '2px solid #00DD9E',
    borderRadius: '20px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
    height: '100%',
  },
  cardContainer: {
    marginLeft: '20px',
    marginRight: '20px',
    padding: '10px 0px 4px 0px !important',
  },

  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  keyboardArrowDownIcon: {
    color: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  customButtonGroup: {
    width: '80%',
    boxShadow: 'none',
    borderRadius: '0px',
    border: 'none',
  },
  customButton: {
    flex: 1,
    margin: '0 1px 20px',
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    backgroundColor: '#06E89F',
    color: '#041370',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '041370',
    },
  },
  customButtonBold: {
    flex: 1,
    margin: '0 1px 20px',
    fontFamily: 'MontserratBold',
    // fontFamily: 'MontserratExtraBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    // backgroundColor: '#06E89F',
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '041370',
    },
  },

  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
export default useStyles;
