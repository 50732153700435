import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootTransactions: {
    borderRadius: '20px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
      paddingLeft: 30,
      paddingRight: 30,
    },
    height: '100%',
  },
  rootLightTransactions: {
    border: '2px solid #00DD9E',
    borderRadius: '20px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
    height: '100%',
  },
  cardContainerTransactions: {
    marginLeft: '20px',
    marginRight: '20px',
    padding: '10px 0px 4px 0px !important',
  },
  MuiAccordionrootTransactions: {
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  keyboardArrowDownIconTransactions: {
    color: 'white',
  },
  accordionDetailsTransactions: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
