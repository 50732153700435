import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';
import Offer from '../../../classes/Offer/Offer';

const DICTIONARY = {
  all: '["BUY", "SELL"]',
  BUY: '["BUY"]',
  SELL: '["SELL"]',
};

const OrdersService = () => {
  const getData = async (id, activeFilter) => {
    const typeFilter = DICTIONARY[activeFilter] || DICTIONARY.all;
    const api = new API(
      `${URL.URL_SERVER}/users/${id}/offers?filter={"type":${typeFilter},"status":["open"],"ne_user":"${id}"}`
    );
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Offer);
      return response.wasSuccessfull().multiple();
    }
  };

  const deleteOffer = async (id) => {
    const api = new API(`${URL.URL_SERVER}/offers/${id}`);
    const http_response = await api.delete();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      if (http_response.status === 200 || http_response.status === 204) {
        return { status: http_response.status, success: true };
      } else {
        console.error('Error al eliminar la oferta: ', http_response.status);
        return { status: http_response.status, success: false };
      }
    }
  };

  return {
    getData,
    deleteOffer,
  };
};

export default OrdersService;
